<hr />
<div class="container-fluid my-2">
  <div class="col-sm-4">
    <h6>
      <img
        class="p-2"
        ngSrc="assets/images/icons/historial.svg"
        alt="historial rtc"
        height="35"
        width="45"
      />Historial RTC
    </h6>
  </div>
  <div class="row">
    <div>
      <form [formGroup]="filterForm">
        <div class="filter-container row">
          <!-- Fecha de Inicio -->
          <div class="form-group">
            <label for="dateRangeStart">Fecha de Inicio</label>
            <input
              type="date"
              id="dateRangeStart"
              formControlName="dateRangeStart"
              class="form-control"
            />
          </div>

          <!-- Fecha de Fin -->
          <div class="form-group">
            <label for="dateRangeEnd">Fecha de Fin</label>
            <input
              type="date"
              id="dateRangeEnd"
              formControlName="dateRangeEnd"
              class="form-control"
            />
          </div>

          <!-- Patente -->
          <div class="form-group col-sm-1">
            <label for="patente">Patente</label>
            <input
              type="text"
              id="patente"
              formControlName="plate"
              placeholder="AABB11"
              class="form-control"
              appInputUppercase
            />
          </div>
          <!-- SAP ID -->
          <div class="form-group col-sm-1">
            <label for="patente">SAP ID</label>
            <input
              type="text"
              id="sapID"
              formControlName="sapID"
              placeholder="85026"
              class="form-control"
            />
          </div>
          <div class="form-group">
            <label for="Transportista">Transportista</label>
            <select
              id="Transportista"
              formControlName="carrier"
              class="form-control"
            >
              <option value="">Seleccione</option>
              <option
                *ngFor="let Transportista of CARRIERS"
                [value]="Transportista.valueId"
              >
                {{ Transportista.valueToDisplay }}
              </option>
            </select>
          </div>

          <div class="form-group">
            <label for="planta">Planta</label>
            <select id="planta" formControlName="center" class="form-control">
              <option value="">Seleccione</option>
              <option *ngFor="let planta of CENTERS" [value]="planta.sapId">
                {{ planta.valueToDisplay }}
              </option>
            </select>
          </div>

          <div class="form-group col-sm-1">
            <label for="resultado">Resultado</label>
            <select
              id="resultado"
              formControlName="result"
              class="form-control"
            >
              <option value="">Seleccione</option>
              <option *ngFor="let option of results" [value]="option.value">
                {{ option.display }}
              </option>
            </select>
          </div>

          <!-- Botón de Filtrar -->
          <div class="col-sm-1 text-center mt-auto">
            <button
              type="button"
              (click)="cleanFilters()"
              class="btn btn-outline-primary htr-button"
            >
              limpiar
            </button>
          </div>
          <div class="col-sm-1 text-center mt-auto">
            <button
              type="button"
              class="btn btn-outline-primary htr-button"
              (click)="onFilter()"
            >
              Filtrar
            </button>
          </div>
          <!-- botton descargar excel //TODO provisional  -->
          <div class="col-sm-1 text-center mt-auto">
            <button
              type="button"
              class="btn btn-outline-primary htr-button"
              (click)="dwonloadExcel()"
            >
              Descargar
            </button>
          </div>
        </div>
      </form>
    </div>
    <br />
    <br />
    <div class="table-responsive-sm" *ngIf="!isGettingEvaluations">
      <table
        datatable
        [dtOptions]="dtOptions"
        class="table display nowrap"
        style="width: 100%"
      >
        <caption></caption>
        <thead>
          <tr>
            <th class="text-center">Negocio</th>
            <th class="text-center">Centro</th>
            <th class="text-center">Tipo Vehículo</th>
            <th class="text-center">Transportista</th>
            <th class="text-center">SAP ID</th>
            <th class="text-center">Patente</th>
            <th class="text-center">Año Fabricación</th>
            <th class="text-center">Fecha Evaluación</th>
            <th class="text-center">Inspector</th>
            <th class="text-center">Resultado Evaluación</th>
            <th class="text-center">Porcentaje Final</th>
            <th class="text-center">Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let evaluation of filteredEvaluations">
            <td>{{ evaluation.business | master : "BUSINESSES" }}</td>
            <td>{{ evaluation.vehicle.center | master : "CENTERS" }}</td>
            <td class="text-center">{{ evaluation.vehicle.__typename }}</td>
            <td>{{ evaluation.vehicle.carrier.name }}</td>
            <td>{{ evaluation.vehicle.sapId | sapId }}</td>
            <td class="text-center">{{ evaluation.vehicle.licensePlate }}</td>
            <td class="text-center">{{ evaluation.vehicle.year }}</td>
            <td class="text-center">
              {{ evaluation.evaluatedAt | date : "dd/MM/yyyy" }}
            </td>
            <td>{{ evaluation.createdBy }}</td>
            <td class="text-center">
              <span>
                <img
                  [ngSrc]="getImageSrc(evaluation.status)"
                  height="25"
                  width="25"
                  alt="Estado"
                />
              </span>
              {{ evaluation.status === "APPROVED" ? "Aprobado" : "Reprobado" }}
            </td>
            <td class="text-center">
              {{ evaluation.score | number : "1.2-2" }}%
            </td>
            <td>
              <label
                class="custom-file-upload pt-2 mx-1"
                style="cursor: pointer"
              >
                <a
                  id="preview-evaluation"
                  title="Visualizar"
                  (click)="onViewDocument(evaluation)"
                >
                  <span class="material-symbols-outlined text-secondary"
                    >preview</span
                  >
                </a>
              </label>
              <label
                class="custom-file-upload pt-2 mx-1"
                style="cursor: pointer"
              >
                <a
                  id="download-evaluation"
                  title="Descargar"
                  (click)="onDownloadDocument(evaluation)"
                >
                  <span class="material-symbols-outlined text-secondary"
                    >download</span
                  >
                </a>
              </label>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <div class="text-center my-5" *ngIf="isGettingEvaluations">
        <app-spinner message="Cargando Historial"></app-spinner>
      </div>
    </div>

    <!-- Modals  -->
    <ng-template #documentViewer let-c="close" let-d="dismiss">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">{{ modalTitle }}</h4>
            <button
              id="document-item-modal-cross"
              type="button"
              class="btn-close"
              aria-label="Close"
              (click)="d('Cross')"
            ></button>
          </div>
          <div class="modal-body">
            <div class="text-center my-5" *ngIf="isGettingURL">
              <app-spinner message="Generando Vista Previa"></app-spinner>
            </div>
            <pdf-viewer
              [src]="pdfSource"
              [render-text]="true"
              [original-size]="false"
              [fit-to-page]="true"
              (page-rendered)="pageRendered()"
              style="width: 800px; height: 70vh"
            >
            </pdf-viewer>
          </div>
          <div class="modal-footer" *ngIf="!isGettingURL">
            <button
              id="document-item-modal-accept"
              type="button"
              class="btn btn-outline-primary"
              (click)="c('Accept')"
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
